import {MalformedRequest} from '@/util/Errors';
import {ServiceProvider} from '@/providers/provider';
import {uniqList} from '@ultradent/utilities/Arrays';
import {Roles} from '@/constants/appConstants';

const svc = new ServiceProvider( {
    basePath: '/user',
    mockSrc: process.env.MOCK_API && 'user'
} );

svc.getUser = function getUser () {
    /** note -> this endpoint does not exist yet */
    return svc.xhr.get( 'getuser' );
};

svc.setRegions = function setRegions ( {azureAdId, regions} ) {
    const body = {azureAdId, regions};
    return svc.xhr.post( 'setregions', body );
}

svc.setPreferredLanguage = function setPreferredLanguage ( {languageCode} ) {
    if ( !languageCode ) {
        throw new MalformedRequest(
            'languageCode',
            `/setPreferredLanguage expects string for crmAccount but received "${languageCode}" instead`
        );
    }
    const body = {PreferredLanguageCode: languageCode};
    return svc.xhr.post( 'setpreferredlanguage', body );
}

svc.confirmProductAcknowledgementAccepted = function setflag () {
    // note -> currently only sets "productAcknowledgementAccepted" flag to true; np params
    return svc.xhr.get( 'setflag' );
};

svc.sendInvite = function sendInvite ( data ) {
    /**
     expected  payload
     {
        String -                "crmAccount" : "1235654698",
        Array(EmailString) -    "users" : ['email1@email.com',"email2@email.com"],
        Array(String) -         "securityGroups" : ["Partner Admin", "Resource Center"]
    }
     */
    const body = {
        ...data,
        // note -> (Roles.MEMBER static) all users should receive this role; Roles.MEMBER gets filtered if in list
        securityGroups: uniqList( [Roles.MEMBER, data.securityGroups] ).filter( Boolean )
    };

    validateSendInviteRequest( body );

    return svc.xhr.post( 'sendinvite', body, {timeout: 0} );

};

export const UserProvider = svc.xhr;

export default svc;

/*Validators*/
export const validateSendInviteRequest = ( {crmAccount, users, securityGroups} ) => {
    if ( !crmAccount ) {
        throw new MalformedRequest(
            'crmAccount',
            `/sendInvite expects string for crmAccount but received "${crmAccount}" instead` );
    }

    if ( !Array.isArray( users ) || users.length <= 0 ) {
        throw new MalformedRequest(
            'users',
            `/sendInvite expects array of email addresses but received "${users}" instead` );
    }
}

