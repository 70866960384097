import React, {useEffect, useState} from 'react';
import env from '@env';
import {Button, Search, SkeletonElement, t, Tooltip} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {AccountLine} from './AccountLine';
import {AccountsContext} from '@/containers/Accounts';
import {Lightbulb, Plus} from '@/components/common/Icons';
import {CreateNewAccount} from '@/components/dialogs/CreateNewAccount';

const accountListCss = 'w-full border border-b-0 border-solid border-grey-lightest';
const PAGE_INCREMENT = 30;
const AccountLookupList = ( {list, refetch} ) => {
    const [accountList, setAccountList] = useState( list || [] );
    const [isCreatingAccount, setIsCreatingAccount] = useState( false );
    const [pageResultCount, setPageResultCount] = useState( PAGE_INCREMENT );

    useEffect( () => setAccountList( list ), [list] );

    function onInviteSuccess ( id ) {
        setAccountList( state => {
            return state.map( line => {
                if ( line.AccountId === id ) {
                    return {...line, IsEligible: 'pending'};
                }

                return line;
            } );
        } );
    }

    return (
        <AccountsContext.Provider value={{onInviteSuccess}}>
            <CreateNewAccount isOpen={isCreatingAccount}
                              onClose={() => setIsCreatingAccount( false )}
                              onCreateSuccess={refetch}/>
            <Search
                resultLimit={10}
                initialQuery={''}
                alwaysShowResults={true}
                queryGroup={[{
                    list: accountList,
                    queryProps: ['Name', 'Email', 'Phone', 'Region'],
                    renderer: AccountLine
                }]}>
                {( search ) =>
                    <>
                        <fieldset className="my-4 max-w-sm">
                            <div className="input-group mb-4">
                                <label htmlFor="accountfilter">
                                    {t( 'account.label.filterAccountList', _.account.label.filterAccountList )}
                                </label>
                                <Search.Input id="accountfilter" placeholder={t(
                                    'account.label.filterAccountListPlaceholder',
                                    _.account.label.filterAccountListPlaceholder
                                )}/>
                            </div>
                        </fieldset>
                        <div className="flex justify-between mb-4">
                            <p className="text-caption mb-2 mr-8 flex-1">
                                <strong>{t( 'label.note', 'Note' )}</strong> &ndash; {t(
                                'account.message.inviteHint',
                                _.account.message.inviteHint
                            )}
                            </p>
                            <div className="flex-1 flex items-center justify-end">
                                <Button icon={Plus} small onClick={() => setIsCreatingAccount( true )}>
                                    {t( 'account.actionLabel.invite', _.account.actionLabel.createNewAccount )}
                                </Button>
                            </div>
                        </div>
                        <div>
                            {(search.isActive || search.hasResults)
                                ? <Search.Results activeClass="bg-near-white" noResultsClass="text-lg p-6">
                                    {( results ) =>
                                        <div className={accountListCss}>
                                            <Search.ResultsPane>{results}</Search.ResultsPane>
                                        </div>
                                    }
                                </Search.Results>
                                :
                                <>
                                    <ul className={accountListCss}>
                                        {accountList.slice( 0, pageResultCount ).map( AccountLine )}
                                    </ul>
                                    {pageResultCount < list.length &&
                                        <div className="my-10 flex flex-col items-center justify-center">
                                        <p className="my-2 text-sm font-semibold">Showing {pageResultCount} of {list.length}</p>
                                        <button onClick={() => setPageResultCount( state => state + PAGE_INCREMENT )}
                                                className="button lg tertiary w-full max-w-sm">
                                            {t( 'actionLabel.loadMore', _.actionLabel.loadMore )}
                                        </button>
                                    </div>
                                    }
                                </>
                            }

                            <Tooltip
                                trigger="click"
                                maxWidth={480}
                                theme="light"
                                content={
                                    <div className="p-3 text-base">
                                        <p className="mb-4">
                                            {t(
                                                'account.message.whyCantISeeAllPartners.tipBody1',
                                                _.account.message.whyCantISeeAllPartners.tipBody1
                                            )}
                                        </p>
                                        <p dangerouslySetInnerHTML={{
                                            __html: t(
                                                'account.message.whyCantISeeAllPartners.tipBody2',
                                                _.account.message.whyCantISeeAllPartners.tipBody2,
                                                {CRMLoginUrl: env.CRM_LOGIN_URL}
                                            )
                                        }}/>
                                    </div>
                                }
                                interactive={true}>
                                   <div className="inline-block my-8">
                                        <p className="text-lg flex items-center cursor-pointer"
                                           aria-haspopup={true}>
                                            <Lightbulb width="24" height="24" aria-label={'Hint'}/>
                                            <span className="ml-2 text-blue">
                                                {t( 'account.message.whyCantISeeAllPartners.heading',
                                                    _.account.message.whyCantISeeAllPartners.heading )}
                                            </span>
                                        </p>
                                   </div>
                            </Tooltip>
                        </div>
                    </>
                }
            </Search>
        </AccountsContext.Provider>
    );
}

AccountLookupList.Skeleton = () =>
    <div>
        <fieldset className="my-4 max-w-sm">
            <div className="input-group mb-4">
               <SkeletonElement className="h-10 bg-transparent border border-grey-lighter"/>
            </div>
        </fieldset>
        <div className="flex justify-between mb-4">
            <div className="mb-2 mr-8 flex-1">
                <SkeletonElement type="text"/>
                <SkeletonElement type="text"/>
            </div>
            <div className="flex-1 flex items-center justify-end">
                <SkeletonElement className="w-56 h-10"/>
            </div>
        </div>
        <div className="border border-grey-lightest">
            <AccountLine.Skeleton/>
            <AccountLine.Skeleton/>
            <AccountLine.Skeleton/>
            <AccountLine.Skeleton/>
        </div>
    </div>;

export {
    AccountLookupList
}

