import {atom, selector} from 'recoil';
import EventService from './service';

export const eventIdState = atom( {
    key: 'eventIdState',
    default: null
} );
export const registrantListRefreshCount = atom( {
    key: 'registrantListRefreshCount',
    default: 0
} );

export const registrantListQuery = selector( {
    key: 'registrantListQuery',
    get: async ( {get} ) => {
        get( registrantListRefreshCount ) // note -> registers refetch count as a dependency to allow for force refresh
        const eventId = get( eventIdState );
        if ( eventId ) {
            const response = await EventService.getEventRegistrants( eventId );
            return {
                list: response.data
            };
        }

        return null;
    }
} );

export const eventDetailsQuery = selector( {
    key: 'eventDetailsQuery',
    get: async ( {get} ) => {
        const eventId = get( eventIdState );
        if ( eventId ) {
            const response = await EventService.getEventDetails( eventId );
            return response.data;
        }

        return null;
    }
} );
