console.warn( '[Mocking] "user" api' );

export default function ( instance ) {

    instance
        .onPost( '/setregions' )
        .reply( () => ([
            200,
            {}
        ]) )

    instance
        .onGet( '/setflag' )
        .reply( () => ([
            200,
            {}
        ]) )

    instance
        .onPost( '/setpreferredlanguage' )
        .reply( () => ([
            200,
            null
        ]) )

    instance
        .onPost( '/sendinvite' )
        .reply( () => ([
            200,
            {}
        ]) )
}
