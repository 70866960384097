import {Button, SkeletonElement, t} from '@ultradent/components';
import React, {useState} from 'react';
import {Download} from '@/components/common/Icons';
import {translationKeys as _} from '@/constants/translations';
import {DownloadAssets} from '@/components/dialogs/DownloadAssets';

export const Header = ( {sharedAssets} ) => {
    const [isOpen, setIsOpen] = useState( false );
    const open = () => setIsOpen( true );
    const close = () => setIsOpen( false );

    const listCount = sharedAssets?.all?.length;

    return (
        <section className="relative bg-white pt-10 pb-4">
        <div className="contain-content pad-content mb-4">
            <div className="md:flex items-end">
                <div className="flex-auto my-2 mr-8">
                    <h3 className="subheading-lg">
                        {t(
                            'assetSharing.list.heading',
                            _.assetSharing.list.heading,
                            {count: listCount}
                        )}
                    </h3>
                    <p>{t( 'assetSharing.list.subheading', _.assetSharing.list.subheading )}</p>
                </div>
                <DownloadAssets
                    allowAnonymous={true}
                    assets={sharedAssets.validated}
                    isOpen={isOpen}
                    onClose={close}
                />
                <Button info large
                        className="text-white w-64 my-2"
                        icon={Download}
                        iconClassName="mx-2"
                        onClick={open}>
                    {t( 'actionLabel.downloadAll', _.actionLabel.downloadAll )}
                </Button>
            </div>
        </div>
        </section>
    );
}

Header.Skeleton = () =>
    <section className="relative bg-white pt-10 pb-4">
        <div className="contain-content pad-content mb-4">
            <div className="flex items-end">
                <div className="flex-auto pr-24">
                    <SkeletonElement type="title"/>
                    <SkeletonElement type="text"/>
                </div>
                <SkeletonElement className="w-64 h-12"/>
            </div>
        </div>
    </section>
