import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import env from '@env';

console.warn('[Initialize client logging]');
/*
    https://github.com/microsoft/ApplicationInsights-JS?tab=readme-ov-file#ICookieMgrConfig
    If disabled during initialization via the disableCookiesUsage or cookieCfg.enabled configurations, 
    you can now re-enable via the ICookieMgr setEnabled function.
*/
const appInsights = new ApplicationInsights({
    config: {
        connectionString: env.APP_INSIGHTS_CONNSTRING,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        enableAutoRouteTracking: true,
        enableDebug: true,
        enableDebugExceptions: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableUnhandledPromiseRejectionTracking: true,
        cookieCfg: {
            enabled: true,
            blockedCookies: ['ai_user']
        }
        /* ...Other Configuration Options... 

            ***** Options that will cause ai cookies to be created:
            enablePerfMgr: true,       
        */
    }
});

// re-enable cookies with the following line 
//appInsights.getCookieMgr().setEnabled(true);
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview