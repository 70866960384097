import React, {useContext} from 'react';
import ProductAnswersRepository from '@/providers/product-answers';
import {FacetedSearchQuery} from '@/containers/FacetedSearch';
import {FaqLine} from '@/apps/ProductAnswers/FaqLine'
import {SearchSuggestions} from '@/apps/ProductAnswers/SearchSuggestions';
import {CurrentQuery} from '@/containers/FacetedSearch/PageNavigation/CurrentQuery';
import {NoResults, ResultList} from '@/containers/FacetedSearch/Components';
import cx from 'classnames';
import SEO from '@/components/common/SEO';
import {SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {ProductAnswersAppContext, verifyOrFallBackContent} from '@/apps/ProductAnswers/appContext';

const ITEMS_PER_PAGE = 15;

export const transformModel = ( data ) => ({
    id: data.Id,
    question: data.Question,
    answer: data.Answer,
    products: data.Products,
    productFamilies: data.ProductFamilies,
    tags: data.Tags
});

/**
 * Take search string from current location and construct a meaningful url for the api
 * This method can be used to transform the final "query" and handle special scenarios
 * Keywords and additional configuration can also be modified
 * @param searchStr
 * @returns {{categoryFilter: string[], query, keyword: (string|string), startAt: (string|number)}}
 */
const constructQuery = ( searchStr ) => {
    const searchParams = new URLSearchParams( searchStr );
    // any facets set on initial request will get passed through on searchStr
    let query = searchStr;

    if ( !searchParams.get( 'limit' ) ) {
        query += `&limit=${ITEMS_PER_PAGE}`;
    }

    return {
        query,
        keyword: searchParams.get( 'keyword' ),
        orderBy: searchParams.get( 'orderBy' ) || '',
        startAt: searchParams.get( 'start' ) || 0
    };
}

const SearchQuery = () => {

    const {pageModelApi} = useContext( ProductAnswersAppContext );
    const pageModel = verifyOrFallBackContent( pageModelApi.pageModel );

    return (
        <FacetedSearchQuery
            queryRepository={ProductAnswersRepository}
            constructQuery={constructQuery}
            itemsPerPage={ITEMS_PER_PAGE}
            sortOptions={[
                {
                    label: t( 'search.label.sortByRelevance', _.search.label.sortByRelevance ),
                    value: ''
                },
                {
                    label: t( 'search.label.sortByNewest', _.search.label.sortByNewest ),
                    value: 'LastUpdated desc'
                }
            ]}
            slotHeader={( {showSkeleton, keyword, onKeywordChange, selectedCategories, onClearCategories} ) =>
                <div className="relative z-20">
                    <SEO title={keyword ? `${pageModel.pageTitle} : ${keyword}` : pageModel.pageTitle}/>
                    <div className="w-full mb-2">
                        <div className="flex items-center">
                            {pageModelApi.loading
                                ? <SkeletonElement className="mr-8 mb-6 mt-2" type="title"/>
                                : <h1 className="subheading-lg mr-8 mb-4 whitespace-nowrap">{pageModel.pageTitle}</h1>
                            }
                        </div>
                        <SearchSuggestions
                            initialQuery={keyword}
                            onSubmit={( e, query ) => onKeywordChange( query.trim() )}/>
                    </div>
                    <CurrentQuery
                        isLoading={showSkeleton}
                        keyword={keyword}
                        onClearFilter={onClearCategories}
                        categoryFilter={selectedCategories}/>
                </div>}
            slotResults={( {
                               results,
                               lastKeyword,
                               hasResults,
                               showSkeleton,
                               showLoadMore,
                               loadingMore,
                               onLoadMore
                           } ) => {

                if ( showSkeleton ) {
                    return <ResultList.Skeleton component={FaqLine}/>;
                }

                return hasResults
                    ? <>
                        <ResultList results={results} keyProp="Id" component={FaqLine} transform={transformModel}/>
                        {showLoadMore &&
                            <button
                                onClick={onLoadMore}
                                disabled={loadingMore}
                                className={cx(
                                    'button lg tertiary mx-auto my-8 w-full max-w-sm',
                                    {'processing': loadingMore}
                                )}>
                                {t( 'actionLabel.loadMore', _.actionLabel.loadMore )}
                            </button>
                        }
                    </>
                    : <NoResults keyword={lastKeyword}/>
            }}
        />
    )
}

export {SearchQuery}
