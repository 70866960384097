import axios from 'axios';
import env from '@env';
import {logResponse, setAnonymousToken, setApiKey, setAuthToken, setLanguagePrefs} from '@/providers/interceptors'
import {ErrorCodes, UnknownError, ValidationError} from '@/util/Errors';

/**
 * resource -> https://www.baeldung.com/rest-api-error-handling-best-practices
 *
 * Expected Error response from apis
 * Error objects will be returned in the following format
 * {
 * "code": "ErrorCode",
 * "message": "Incorrect username and password",
 * "detail": "Ensure that the username and password included in the request are correct"
 * }
 * String errorCode: can be used as key for handling known error types
 * String message: is meant to be displayed to user in  UI and should be translatable if client
 *  sends an Accept-Language header
 * String detail: is a non-translated message for the developer for logging/debugging purposes
 */

/**
 * @param basePath - path to api
 * @param methods - object containing function calls to be called in context of this axios instance
 * @param options - request options to pass to axios instance
 * @returns {*}
 */
class ServiceProvider {
    static throwErrorFromResponse ( errResponse ) {
        const {code, message} = errResponse.data;

        if ( code === ErrorCodes.Validation ) {
            throw new ValidationError( message );
        }
        throw new UnknownError();
    }

    constructor ( {baseURL, basePath = '', methods = {}, options = {}, doMock = false, mockSrc} = {} ) {

        const instance = axios.create( {
            baseURL: baseURL || env.API_ORIGIN + basePath,
            timeout: 20000, // 20 sec
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            useAccessToken: 'auth',
            requireApiKey: false,
            ...options
        } );

        setLanguagePrefs(instance);

        // todo: add config option to exclude authorization header

        if ( instance.defaults.useAccessToken === 'auth' ) {
            setAuthToken( instance )
        }
        if ( instance.defaults.useAccessToken === 'anonymous' ) {
            setAnonymousToken( instance );
        }
        if ( instance.defaults.requireApiKey ) {
            setApiKey( instance );
        }

        return {
            ...methods,

            xhr: instance,

            mock: createMockApi( instance, doMock, mockSrc )
        };
    }
}

/**
 * Covert js Object to FormData string for post apis expecting application/x-www-form-urlencoded format
 * @param data
 * @returns {FormData}
 */
export function toFormData ( data = {} ) {
    const formData = new FormData();
    Object.entries( data ).forEach( ( [key, value] ) => formData.append( key, value ) );
    return formData;
}

function noop () {
    console.log( 'No operation' );
}

const createMockApi = ( instance, doMock, mockSrc ) => {
    if ( doMock || mockSrc ) {
        const MockAdapter = require( 'axios-mock-adapter' ).default;
        const mockAdapter = new MockAdapter( instance, {delayResponse: 60} );

        if ( mockSrc ) {
            const generateMock = require( `./_mocks/${mockSrc}` ).default;
            generateMock( mockAdapter );
        }

        logResponse( mockAdapter.axiosInstance );

        return mockAdapter;
    }

    return {
        onGet: () => ({reply: noop}),
        onPost: () => ({reply: noop})
    }
}

export {ServiceProvider};





