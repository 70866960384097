import React from 'react';
import {Article as ArticleIcon} from '@/components/common/Icons';
import {ArticleTitle, SynopsisLine} from '@/apps/Studies/PublicationTile/Components';
import {Link} from 'react-router-dom';
import {t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';

export const PopularStudiesSection = ( {title, articleCollections = []} ) =>
    <section className="py-8 my-16 md:py-16 md:my-32">
        <div className="contain-content pad-content">
            <h2 className="headline-lg mb-12">{title}</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6">
                {articleCollections?.map( topic =>
                    <div className="p-8 bg-near-white w-full rounded-lg" key={`top-studies-${topic.headline}`}>
                        <p className="eyebrow-headline-xs">
                            {t( 'researchStudiesPage.label.topic', _.researchStudiesPage.label.topic )}
                        </p>
                        <h3 className="subheading-lg">{topic.headline}</h3>
                        <p className="text-body">{topic.subtitle}</p>
                        <div>
                            {topic.articles?.map( article =>
                                <article className="my-12 flex"
                                         key={`article-${topic.headline}-${article.product}`}>
                                    <ArticleIcon className="w-8 md:w-12 flex-none self-start"/>
                                    <div className="ml-4 md:ml-8">
                                        <h4 className="text-sm uppercase tracking-wider text-grey-darkest">{article.product}</h4>
                                        <ArticleTitle url={article.url} title={article.title}/>
                                        <h5 className="text-base txt-c2 mb-4">{article.citation}</h5>
                                        <SynopsisLine withClaim={article.claim}/>
                                    </div>
                                </article>
                            )}

                            <Link className="button textLink" to="/search?keyword=">
                                {t( 'researchStudiesPage.actionLabel.browseAll',
                                    _.researchStudiesPage.actionLabel.browseAll )}
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </section>
