import {ServiceProvider} from '@/providers/provider';

const svc = new ServiceProvider( {
    basePath: '/event',
    mockSrc: process.env.MOCK_API && 'events'
} );

svc.submitEventRegistrants = function createAccount ( registrants ) {
    return svc.xhr.post( 'registrationrequests', registrants );
}

svc.getEventRegistrants = function getEventRegistrants ( eventId ) {
    return svc.xhr.get( `getregistrationrequests`, {params: {id: eventId}} );
}

svc.updateEventRegistrant = function updateEventRegistrant ( registrant ) {
    return svc.xhr.post( `updateregistration`, registrant );
}

svc.getEventDetails = function getEventDetails ( eventId ) {
    return svc.xhr.get( `geteventdetails`, {params: {id: eventId}} );
}

export const EventManagementProvider = svc.xhr;

export default svc;


