import React from 'react';
import {SearchSuggestions} from '@/apps/Studies/SearchSuggestions';
import {useNavigate} from 'react-router-dom';
import {SkeletonElement} from '@ultradent/components';

export const HeaderSection = ( {backgroundImage, title, subtitle, loading} ) => {
    const navigate = useNavigate();
    return (
        <header className="h-96 relative bg-grey-lightest" style={{height: '480px'}}>
            <div className="contain-content h-full flex justify-start items-center">
                <div className="relative z-10 px-4 mb-8 md:px-12 w-full max-w-lg">
                    <div className="text-white text-left mb-8">
                        {loading
                            ?
                            <>
                                <SkeletonElement className="m-0 mb-8 h-12 w-1/2"/>
                                <SkeletonElement type="text"/>
                            </>
                            :
                            <>
                                <h1 className="headline-lg leading-none m-0 mb-4">{title}</h1>
                                <div dangerouslySetInnerHTML={{__html: subtitle}}/>
                            </>
                        }
                    </div>
                    <div>
                        <SearchSuggestions onSubmit={( e, query ) => {
                            navigate( {
                                pathname: '/search',
                                search: `?keyword=${query}`
                            } )
                        }}/>
                    </div>
                </div>
            </div>
            <picture>
                <source data-srcset={backgroundImage}/>
                <img data-src={backgroundImage}
                     className="cover-image clip-chevron-hero lazyload fade-up"
                     alt="Lab scene"/>
            </picture>
        </header>
    );
}

