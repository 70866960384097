import {useEffect, useState} from 'react';
import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {guid} from '@ultradent/utilities/Guid';
import EventRegistrantRepository from './repository';
import eventRegistrationStore from './store';
import EventService from './service';
import {ErrorCodes, ErrorMessages} from '@/util/Errors';
import {eventFormDataToRegistrant} from './transformers';

const repository = new EventRegistrantRepository( eventRegistrationStore );

export function useEventRegistration ( {eventId, onSuccess} ) {
    const [registrants, setRegistrants] = useState( null );
    const [registrant, setRegistrant] = useState( null );
    const [submissionErrors, setSubmissionsErrors] = useState( [] );
    const [isSubmitted, setIsSubmitted] = useState( false );
    const [isProcessing, setIsProcessing] = useState( false );
    const [postErrors, setPostErrors] = useState( [] );

    // note - cleanup empty records in repository
    useEffect( () => {
        const initialState = repository.getRegistrants( eventId );
        setRegistrants( initialState );

        const unsubscribeLocal = repository.subscribe( data => {
            setRegistrants( data );
        } );

        async function onUnload () {
            const data = repository.getRegistrants( eventId );
            if ( isEmptyOrNil( data ) ) {
                // if nothing was entered then clear out this record list
                repository.unloadRegistrantList( eventId );
            }
        }

        window.addEventListener( 'beforeunload', onUnload );

        return () => {
            unsubscribeLocal();
            window.removeEventListener( 'beforeunload', onUnload, true );
        }
    }, [] );

    useEffect( () => {
        // note - we only want to set postErrors for a form if we have a list of indexed submission errors, otherwise this is treated as a generic submissions error
        if ( Array.isArray( submissionErrors ) ) {
            const postError = submissionErrors.find( err => err && registrants[err.index] === registrant )
            setPostErrors( postError?.responseData );
        }
    }, [submissionErrors, registrant] );

    useEffect( () => {
        if ( isSubmitted ) {
            setTimeout( () => setIsSubmitted( false ), 3000 );
        }
    }, [isSubmitted] );

    function handleRequestError ( err ) {
        if ( err.response && err.response.data.code ) {
            return parseRequestErrorResponse( err.response.data );
        }

        return {
            message: ErrorMessages.UnknownError
        }
    }

    function parseRequestErrorResponse ( err ) {
        if ( err.code === ErrorCodes.Validation ) {
            /* expected error format
            {
                "code": "ValidationError",
                "message": String,
                "data": [
                    { index: 0, issues: {"name": FieldName<String>, "value": String, "issue": Sting }}
                    { index: 2, issues: {"name": FieldName<String>, "value": String, "issue": Sting }}
                ]
            }*/

            return err.data.map( ( {index, issues} ) => ({
                index,
                responseData: {
                    message: 'Please review the following issues and resubmit your request',
                    errors: issues.map( ( {value, issue} ) => `"${value}" ${issue}` )
                }
            }) );
        }

        return {
            message: ErrorMessages.UnknownError
        }
    }

    function resetRegistrantList ( eventId ) {
        // clear out registrant store
        repository.unloadRegistrantList( eventId );

        setRegistrant( null );
        setIsProcessing( false );
        setPostErrors( [] );
        setSubmissionsErrors( [] );

        // initialize new registrant store
        setRegistrants( repository.getRegistrants( eventId ) );
    }

    function onSaveRegistrant ( registrant ) {
        const exists = registrants.find( r => r.id === registrant.id );
        if ( notEmptyOrNil( exists ) ) {
            repository.updateRegistrant( {eventId, registrants, registrant} );
        }
        else {
            repository.addRegistrant( {eventId, registrant} );
        }

        setRegistrant( null );
    }

    function onCancelEditRegistrant () {
        setRegistrant( null );

        // if no registrants are stored then navigate back in history
        if ( isEmptyOrNil( registrants ) ) {
            window.location = `/events/${eventId}`;
        }
    };

    function onAddRegistrant () {
        // new up a new user guid to ensure memoized "registrant" model is fresh
        setRegistrant( {id: guid()} );
    }

    function onEditRegistrant ( registrantId ) {
        const foundRegistrant = registrants.find( ( {id} ) => id === registrantId );
        if ( foundRegistrant ) {
            setRegistrant( foundRegistrant );
            return;
        }

        console.error( `[EventRegistration:onEdit] - Registrant with id ${registrantId} not found` );
    }

    function onRemoveRegistrant ( registrantId ) {
        repository.removeRegistrant( {eventId, registrants, registrantId} );
    }

    async function onSubmitRegistration () {
        setIsProcessing( true );

        try {
            await EventService.submitEventRegistrants( registrants.map( eventFormDataToRegistrant ) );
            // complete submissions process; clear out forms;
            resetRegistrantList( eventId );
            setIsSubmitted( true );

            if ( typeof onSuccess === 'function' ) {
                onSuccess();
            }
        }
        catch ( err ) {
            setSubmissionsErrors( handleRequestError( err ) );
        }
        finally {
            setIsProcessing( false );
        }
    }

    return {
        loading: registrants === null,
        registrants,
        currentRegistrant: registrant,
        isSubmitted,
        isProcessing,
        isEditing: notEmptyOrNil( registrant ),
        submissionErrors,
        postErrors,
        onAddRegistrant,
        onRemoveRegistrant,
        onEditRegistrant,
        onSaveRegistrant,
        onCancelEditRegistrant,
        onSubmitRegistration
    }
}
