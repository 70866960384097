import MediaQueries from '@ultradent/utilities/MediaQueries';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import {SkeletonElement, t} from '@ultradent/components';
import imageEnamelast from '@/assets/images/products/product-enamelast.png';
import {translationKeys as _} from '@/constants/translations';

const enamelastSmallStyle = {
    width: '500px',
    transform: `translate(-30%, 10%)`
};

const enamelastMediumStyle = {
    width: '700px',
    transform: `translate(-30%, 10%)`
};

const enamelastLargeStyle = {
    transform: `translate(-30%, 10%)`
};

export const PopularTopicsSection = ( {title, subtitle, tiles = [], loading = false} ) => {
    const mqSm = useMediaQuery( {query: MediaQueries.max.screenMedium} )
    const mqMd = useMediaQuery( {query: MediaQueries.max.screenLarge} )
    let enamelastStyle = enamelastLargeStyle;
    if ( mqSm ) {
        enamelastStyle = enamelastSmallStyle;
    }
    if ( mqMd ) {
        enamelastStyle = enamelastMediumStyle;
    }

    return (
        <section className="relative bg-grey-lightest mb-16 pt-10 pb-32 md:py-48">
            <div className="contain-content pad-content">
                    <div className="md:flex flex-row-reverse">
                        <div className="flex md:w-1/2 items-center justify-center">
                            <div className="mb-8 md:m-0 md:max-w-xs w-full">
                             {loading
                                 ?
                                 <>
                                     <SkeletonElement className="m-0 mb-8 h-12 bg-grey-light w-full"/>
                                     <SkeletonElement type="text" className="bg-grey-light"/>
                                     <SkeletonElement type="text" className="bg-grey-light"/>
                                     <SkeletonElement type="text mb-16" className="bg-grey-light"/>
                                     <Link className="button lg gradient" to="/search?keyword=">
                                         {t( 'researchStudiesPage.actionLabel.browseAll',
                                             _.researchStudiesPage.actionLabel.browseAll )}
                                     </Link>
                                 </>
                                 :
                                 <>
                                     <h2 className="headline-lg leading-tight mb-4">{title}</h2>
                                     <p className="text-body mb-8">{subtitle}</p>
                                     <Link className="button lg gradient" to="/search?keyword=">
                                         {t( 'researchStudiesPage.actionLabel.browseAll',
                                             _.researchStudiesPage.actionLabel.browseAll )}
                                     </Link>
                                 </>
                             }
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6 md:w-1/2">
                            {loading ?
                                <>
                                    <SkeletonElement className="h-32 bg-white"/>
                                    <SkeletonElement className="h-32 bg-white"/>
                                    <SkeletonElement className="h-32 bg-white"/>
                                    <SkeletonElement className="h-32 bg-white"/>
                                </>
                                :
                                tiles?.map( ( {productFamily, topicKeyword, topicFacet} ) =>
                                    <Link
                                        data-testid="PopularTopicTile"
                                        key={productFamily}
                                        className="bg-eggshell-white rounded-lg hover:bg-white hover:text-blue t-color t-duration-short"
                                        to={`/search?keyword=&${topicFacet}=${encodeURIComponent( topicKeyword )}`}>
                                    <div className="flex flex-col h-full py-6 px-8 w-full">
                                        <p className="eyebrow-headline-xs">
                                            {t( 'researchStudiesPage.label.topic', _.researchStudiesPage.label.topic )}
                                        </p>
                                        <h3 className="flex-1 subheading-lg leading-tight mb-4 md:mb-8">{productFamily}</h3>
                                        <span className="link-2 justify-self-end">
                                            {t( 'researchStudiesPage.actionLabel.browseArticles',
                                                _.researchStudiesPage.actionLabel.browseArticles )}
                                        </span>
                                    </div>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            <img src={imageEnamelast}
                 alt="Enamelast"
                 className="absolute left-0"
                 style={enamelastStyle}
                 width="900"/>
        </section>
    );
}

