import UserProvider from '@/providers/user';
import pathOr from 'ramda/src/pathOr';

const getUser = () => {
    try {
        const user = window.sessionStorage.getItem( 'UpiPortalUser' );
        return JSON.parse( user );
    }
    catch ( err ) {
        return null;
    }
};

const setUSer = ( user ) => {
    if ( !user ) {
        console.error( `Error setting "UpiPortalUser": expect user data but received ${user} instead` );
        return;
    }
    window.sessionStorage.setItem( 'UpiPortalUser', JSON.stringify( user ) );
};

const fetchUser = () => UserProvider.getUser();

const User = {
    setLanguagePreference ( languageCode ) {
        return UserProvider.setPreferredLanguage( {languageCode} );
    },
    /** Note: methods below not implemented; API for getUser does not exist yet**/
    set: setUSer,
    fetch: fetchUser,
    async me () {
        let user = getUser();
        if ( !user ) {
            try {
                const resp = await fetchUser();
                user = pathOr( null, ['data', 'Values', 'User'] )( resp );
                setUSer( user );
                return user;
            }
            catch ( err ) {
                console.error( 'UserProvider "get" failed' );
                throw Error( err.message );
            }
        }

        return Promise.resolve( user );
    }
};

export default User;
