import {MediaTile} from '@/components/common/MediaTile/MediaTile';
import {SkeletonElement} from '@ultradent/components';

const tileSkeletonCount = Array( 6 ).fill( null );

export const PopularCategoriesSection = ( {title, subtitle, list, loading} ) =>
    <div className="mb-16 md:mb-32">
        <div className="max-w-md mx-auto text-center mb-6 md:mb-10">
            {loading
                ?
                <>
                    <SkeletonElement className="mx-auto mb-6 h-12 w-1/2"/>
                    <SkeletonElement type="paragraph" className="mx-auto mb-5 w-2/3"/>
                    <SkeletonElement type="paragraph" className="mx-auto mb-10 w-1/2"/>
                </>
                :
                <>
                    <h2 className="headline-lg leading-tight text-near-black mb-4">{title}</h2>
                    <p className="text-body text-grey-darkest">{subtitle}</p>
                </>
            }
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {loading
                ? tileSkeletonCount.map( ( x, i ) => <MediaTile.Skeleton key={`PopularCategoriesSectionTile-${i}`}/> )
                : list.map( itemProps => <MediaTile key={itemProps.id} {...itemProps} /> )
            }
        </div>
    </div>
