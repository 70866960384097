import flatten from 'ramda/src/flatten';
import PropTypes from 'prop-types';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {AccordionItem, Show, SkeletonElement, t} from '@ultradent/components';
import {translationKeys as _} from '@/constants/translations';
import {Card, CardActions, CardContent, CardFooter} from '@/components/common/Card';
import {ArticleTitle, MentionLine, RequestLine, SynopsisLine, Taglist} from './Components';
import {FormatGeneric} from '@/components/common/Icons';

function buildCategorizedTagList ( categories = {} ) {
    // note -> these categories are hardcoded for the time being until variable facets are needed
    const categorize = ( list, group ) => Array.isArray( list ) ? list.map( value => ({group, value}) ) : null;
    const productFamilyGroup = categorize( categories.productFamilies, 'ProductFamilies' );
    const productGroup = categorize( categories.products, 'Products' );

    return flatten( [productFamilyGroup, productGroup].filter( Boolean ) );
}

const PublicationTile = ( props ) => {
    const {
        id,
        title,
        articleUrl,
        productFamilies,
        products,
        publishDate,
        specificCountries,
        citation,
        citedMaterials,
        synopsis,
        requestRequired,
        upiMentioned
    } = props;

    const categories = buildCategorizedTagList( {products, productFamilies} );

    return (
        <Card data-testid="PublicationTile" data-asset-id={id} actionPosition="bottom">
            <CardContent>
                <ArticleTitle requestRequired={requestRequired} url={articleUrl} title={title}/>
                <p className="text-base txt-c2 mb-4 break-all whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{__html: citation}}/>

                <div className="mb-4">
                    <Show when={notEmptyOrNil( publishDate )}>
                        <span>{t( 'publicationDetail.label.published',
                            _.publicationDetail.label.published )} {publishDate}</span>
                    </Show>
                    <Show when={notEmptyOrNil( specificCountries )}>
                        <span className="ml-4 font-bold text-near-black">{specificCountries.join( ', ' )} only</span>
                    </Show>
                    <SynopsisLine withClaim={synopsis}/>
                </div>

                <MentionLine withMention={upiMentioned}/>
                <RequestLine title={title} assetId={id} withRequest={requestRequired}/>
            </CardContent>

            <Show when={notEmptyOrNil( categories )}>
                <CardFooter>
                    <Taglist tags={categories}/>
                </CardFooter>
            </Show>

            <Show when={notEmptyOrNil( citedMaterials )}>
                <AccordionItem
                    headingClassName="px-6 py-3"
                    className="border-t border-grey-lighter"
                    label={t( 'publicationDetail.label.citedMaterial', _.publicationDetail.label.citedMaterial )}>
                    <CardContent className="pt-2">
                        {citedMaterials.map( ( item, index ) =>
                            <li key={`cited-${id}-${index}`} className="flex items-center">
                                <FormatGeneric width="16" className="mr-2"/>
                                {item}
                            </li>
                        )}
                    </CardContent>
                </AccordionItem>
            </Show>

            {/*note - Future implementation
            <CardActions showOnHover>
                <ContextMenu {...props} />
            </CardActions>
            */}
        </Card>
    );
}

PublicationTile.Skeleton = ( {className = ''} ) =>
    <Card className={className} data-testid="PublicationTile.Skeleton" actionPosition="right">
        <CardContent>
            <SkeletonElement.Paragraph className="mb-4"/>
            <SkeletonElement type="text" className="mb-4"/>
            <SkeletonElement type="tag"/>
            <SkeletonElement type="tag"/>
        </CardContent>
        <CardActions>
            <SkeletonElement type="icon"/>
        </CardActions>
    </Card>;

PublicationTile.defaultProps = {
    tag: [],
    relatedAssets: [],
    productFamilies: [],
    products: [],
    citedMaterials: [],
    articleUrl: null,
    upiMentioned: false,
    requestRequired: false,
    specificCountries: [],
    synopsis: null
};

PublicationTile.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    citation: PropTypes.string.isRequired,
    publishDate: PropTypes.string.isRequired

};

export {PublicationTile}
